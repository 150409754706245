import { reactive } from 'vue';

const data = reactive({
    confirmed: false,
    id: '',
});

export default function () {
    return {
        id: computed(() => data.id),
        isConfirmed: computed(() => data.confirmed),
        confirmWithId: (id: string) => {
            data.confirmed = true;
            data.id = id;
        },
    };
}
