import type { EnrichedOrderLine } from '~/types';

export async function getPayload() {
    const { order } = await useActiveOrder();
    const customerData = useCustomerData();
    const token = useToken();
    const { shippingMethod, dropPoint } = await useShippingConfig();
    const { enrichedOrderlines } = await useActiveOrder();
    const posContext = await usePosContext();

    // AD-208: provide MDH data
    const mdh =
        enrichedOrderlines.value &&
        enrichedOrderlines.value.map((line: EnrichedOrderLine) => {
            return line.mdh
                ? {
                      itemCode: line.ArticleId,
                      itemName: line.mdh.itemName,
                      weight: line.mdh.weight,
                      weightUnit: line.mdh.weightUnit,
                      // OD-103: Include unCode
                      dangerousGoodsSpecifications: line.mdh
                          .dangerousGoodsSpecifications
                          ? {
                                unCode: line.mdh.dangerousGoodsSpecifications
                                    .unCode,
                            }
                          : null,
                  }
                : null;
        });

    // remove carrier_logo from shippingMethod
    const { carrier_logo, ...shippingMethodWithoutCarrierLogo } =
        shippingMethod.value || {};

    const payload = {
        customerData: customerData.value,
        receiptRaw: order,

        // OD-116: Include POS Context
        posContext: posContext.value,

        // OD-98: Include token metadata
        storeData: {
            cc_ws_rate_id: token.cc_ws_rate_id,
            company_code: token.company_code,
            login_msg: token.login_msg,
            main_wh_rate_id: token.main_wh_rate_id,
            main_wh_site_id: token.main_wh_site_id,
            main_wh_stock_id: token.main_wh_stock_id,
            store_display_name: token.store_display_name,
            store_id: token.store_id,
            store_main_currency: token.store_main_currency,
            row_key: token.row_key,
        },

        // Shipping data
        shippingMethod: shippingMethodWithoutCarrierLogo,
        dropPoint: customerData.value.webshipperData.dropPointId
            ? dropPoint.value
            : null,

        // AD-208: provide MDH data
        masterDatahub: mdh,
    };

    return payload;
}
